import Routes from 'types/enums/routes'

import { ApplicationScopes } from 'types/enums/scopes'

import type { RoutesConfig } from 'types/routes'

/**
 * Main App Route Config
 * @constant
 * @type {RouteConfig}
 *
 * The route config is used to store all permissions for each route.
 */

const publicRouteConfig: RoutesConfig = {
  [Routes.PUBLIC_PROPOSALS]: {
    scope: ApplicationScopes.ALL
  }
}

export default publicRouteConfig
