/**
 * Styles utility functions
 */

import { hexToRgb } from '@mui/material'

export { default as generateColorFromString } from './generateColorFromString'

// Typography Helpers -------------------------------------------

/**
 * Convert rem to px
 */
export const remToPx = (value: string, rem: number = 16) => Math.round(parseFloat(value) * rem)

/**
 * Convert px to rem
 */
export const pxToRem = (value: number, rem: number = 16) => `${value / rem}rem`

// Palette Helpers -------------------------------------------

/**
 * Convert hex to rgba
 */
export const hexToRgba = (hex: string, opacity: number = 1) => `rgba(${hexToRgb(hex)},${opacity})`

/**
 * Change opacity of an RGBA color
 */
export const changeRGBAOpacity = (color: string, opacity: number) => {
  const [r, g, b, a] = color.match(/\d+/g)!.map(Number)

  return `rgba(${r},${g},${b},${a || 1 * opacity})`
}
