import Jsona from 'jsona'

import { JsonDeserializer, JsonPropertiesMapper } from 'utils/jsona'

const dataFormatter = new Jsona({
  jsonPropertiesMapper: new JsonPropertiesMapper(),
  JsonDeserializer: JsonDeserializer
})

export default dataFormatter
