/* eslint-disable @typescript-eslint/no-var-requires */
const path = require('path')

const ChainedBackend = require('i18next-chained-backend').default
const HttpBackend = require('i18next-http-backend').default
const LocalStorageBackend = require('i18next-localstorage-backend').default
const MultiloadAdapter = require('i18next-multiload-backend-adapter/cjs')

const isBrowser = typeof window !== 'undefined'

const backendAPIUrl = '/api/translation?lng={{lng}}&ns={{ns}}'
const localePath = !isBrowser ? path.resolve('./public/locales') : backendAPIUrl

// eslint-disable-next-line turbo/no-undeclared-env-vars
const isDevelop = process.env.NODE_ENV === 'development'

const cacheExpiration = 10 * 1000 // 10 seconds.

/** @type {import('next-i18next').UserConfig} */
module.exports = {
  // debug: true,
  ns: ['common', 'navigation'], // Preload common & navigation namespace
  interpolation: { escapeValue: false }, // React is already safe from xss.
  i18n: { defaultLocale: 'en', locales: ['en', 'es'] },
  react: { useSuspense: true },
  localePath,
  partialBundledLanguages: isBrowser,
  reloadOnPrerender: isDevelop,
  serializeConfig: false,
  use: isBrowser ? [ChainedBackend] : [],
  backend: {
    backends: [LocalStorageBackend, MultiloadAdapter],
    backendOptions: [
      { expirationTime: cacheExpiration },
      { backend: HttpBackend, backendOption: { loadPath: backendAPIUrl } }
    ]
  }
}
