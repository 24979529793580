import axios from 'axios'

import Qs from 'qs'

import useChainContextStore from 'store/chainContext'
import { ET_JWT_LOCAL_STORAGE_KEY, ET_REVIEW_APP_API_URL_KEY } from 'utils/constants/global'

import dataFormatter from 'utils/dataFormatter'

import type { AxiosRequestHeaders } from 'axios'

const api = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL
})

api.defaults.withCredentials = true
api.defaults.withXSRFToken = true

api.interceptors.response.use((response) => {
  try {
    const deserializeData = dataFormatter.deserialize(response.data)

    response.data.normalizedData = deserializeData
  } catch {
    // do nothing
  }

  return response
})

api.interceptors.request.use((config) => {
  config.paramsSerializer = (params) =>
    Qs.stringify(params, {
      arrayFormat: 'brackets',
      encode: false
    })

  config.headers = {
    'X-Internal-Api': true,
    'X-API-ORG': useChainContextStore.getState().selectedOrganization?.subdomain,
    ...config.headers
  } as unknown as AxiosRequestHeaders

  if (process.env.NEXT_PUBLIC_AUTH_MODE == 'JWT') {
    const jwt = localStorage.getItem(ET_JWT_LOCAL_STORAGE_KEY)

    const reviewApiUrl = localStorage.getItem(ET_REVIEW_APP_API_URL_KEY)

    if (jwt) {
      config.headers = {
        ...config.headers,
        Authorization: `JWT ${jwt}`
      } as AxiosRequestHeaders
    }

    if (reviewApiUrl) {
      config.baseURL = reviewApiUrl
    }
  }

  return config
})

export default api
