import type { ActionParams } from 'types/action'

import type { TableAction } from 'types/dataGrid'

export const editAction = <T>({ iconType, label, ...others }: ActionParams<T>): TableAction<T> => ({
  key: 'edit',
  label: label ?? 'Edit',
  iconType: iconType ?? 'Edit',
  ...others
})

export const deleteAction = <T>({
  iconType,
  label,
  ...others
}: ActionParams<T>): TableAction<T> => ({
  key: 'delete',
  label: label ?? 'Delete',
  iconType: iconType ?? 'Delete',
  ...others
})

export const viewAction = <T>({ iconType, label, ...others }: ActionParams<T>): TableAction<T> => ({
  key: 'view',
  label: label ?? 'View',
  iconType: iconType ?? 'View',
  ...others
})

export const previewAction = <T>({
  iconType,
  label,
  ...others
}: ActionParams<T>): TableAction<T> => ({
  key: 'preview',
  label: label ?? 'Preview',
  iconType: iconType ?? 'Preview',
  ...others
})

export const deactivateAction = <T>({
  iconType,
  label,
  ...others
}: ActionParams<T>): TableAction<T> => ({
  key: 'deactivate',
  label: label ?? 'Deactivate',
  iconType: iconType ?? 'Deactivate',
  ...others
})

export const activateAction = <T>({
  iconType,
  label,
  ...others
}: ActionParams<T>): TableAction<T> => ({
  key: 'activate',
  label: label ?? 'Activate',
  iconType: iconType ?? 'Activate',
  ...others
})

export const archiveAction = <T>({
  iconType,
  label,
  ...others
}: ActionParams<T>): TableAction<T> => ({
  key: 'archive',
  label: label ?? 'Archive',
  iconType: iconType ?? 'Archive',
  ...others
})

export const unarchiveAction = <T>({
  iconType,
  label,
  ...others
}: ActionParams<T>): TableAction<T> => ({
  key: 'unarchive',
  label: label ?? 'Unarchive',
  iconType: iconType ?? 'Unarchive',
  ...others
})

export const addAction = <T>({ iconType, label, ...others }: ActionParams<T>): TableAction<T> => ({
  key: 'add',
  label: label ?? 'Add',
  iconType: iconType ?? 'Add',
  ...others
})

export const cloneAction = <T>({
  iconType,
  label,
  ...others
}: ActionParams<T>): TableAction<T> => ({
  key: 'clone',
  label: label ?? 'Clone',
  iconType: iconType ?? 'Clone',
  ...others
})

export const customAction = <T>({ icon, label, ...others }: ActionParams<T>): TableAction<T> => ({
  key: `custom-${label}`,
  label: label ?? 'Custom',
  icon,
  ...others
})
