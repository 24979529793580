import useChainContextStore from 'store/chainContext'

const use24HourClock = (): boolean => {
  const use24HourTime = useChainContextStore(
    (state) => state.selectedOrganization?.organization_setting?.use24_hour_time ?? false
  )

  return use24HourTime
}

export default use24HourClock
