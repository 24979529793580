import { useEffect, useMemo, useRef, useState } from 'react'

import type { ReactNode } from 'react'

import Profitwell from './Profitwell'
import { ProfitwellContext } from './ProfitwellContext'
import ProfitwellLoader from './ProfitwellLoader'

type ProfitwellProviderProps = {
  appToken: string
  children: ReactNode
  userId?: string | number
}

const ProfitwellProvider = ({ appToken, children, userId }: ProfitwellProviderProps) => {
  const [isLoaded, setIsLoaded] = useState(false)

  const refProfitwell = useRef(null)

  useEffect(() => {
    const load = async () => {
      const loader = new ProfitwellLoader()

      if (!appToken) {
        return console.error(
          'An appToken is required to initialize Profitwell. Skipping initialization...'
        )
      }

      try {
        refProfitwell.current = await loader.load(appToken)

        setIsLoaded(true)
      } catch (error) {
        console.error(
          'Error loading Profitwell. User engagement stats will not be tracked for this session.',
          error
        )
      }
    }

    if (!isLoaded && userId) load()
  }, [userId, appToken, isLoaded])

  // Intentional eslint override. We want to memoize the instance of Profitwell
  // whenever the script is loaded.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const profitwell = useMemo(() => new Profitwell(refProfitwell.current), [isLoaded])

  useEffect(() => {
    if (!isLoaded || !userId) return

    profitwell.initialize(userId)
  }, [profitwell, isLoaded, userId])

  const contextValue = useMemo(() => ({ isLoaded, profitwell }), [isLoaded, profitwell])

  return <ProfitwellContext.Provider value={contextValue}>{children}</ProfitwellContext.Provider>
}

export default ProfitwellProvider
