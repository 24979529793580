export enum StaticAssets {
  LOGO = '/et-logo.svg',
  IMAGE_UPLOAD = '/static/ImageUpload.png',
  STOREFRONT = '/static/storeFront.png',
  NOT_FOUND = '/static/notFound.png',
  SERVER_ERROR = '/static/serverError.png',

  PROPOSAL_HEADER = '/static/proposalHeader.jpeg',

  PLACEHOLDER = '/static/placeholder.png'
}
