import { type ReactNode, useMemo } from 'react'

import { CannyProvider as RealCannyProvider } from 'react-canny'

import useCurrentUserStore from 'store/currentUser'

import { CANNY_APP_ID } from 'utils/constants/global'

const CannyProvider = ({ children }: { children: ReactNode }) => {
  const currentUser = useCurrentUserStore((state) => ({
    id: state.id,
    email: state.email,
    name: state.name,
    isChainAdmin: state.isChainAdmin,
    chain: state.chain,
    chain_organizations: state.chain_organizations
  }))

  const cannyUser = useMemo(
    () => ({
      id: currentUser.id,
      email: currentUser.email,
      name: currentUser.name,
      customFields: {
        chainAdmin: currentUser.isChainAdmin,
        chain: currentUser.chain?.name
      },
      companies: currentUser.chain_organizations?.map((org) => ({
        id: org.id,
        name: org.name,
        created: org.created_at,
        monthlySpend: org?.subscription?.plan?.monthly_spend_in_dollars || 0,
        customFields: {
          companyType: org.vendor_type,
          chain: org?.chain?.name,
          country: org?.address?.country,
          website: org.website,
          plan: org?.subscription?.plan?.name
        }
      }))
    }),
    [
      currentUser.id,
      currentUser.email,
      currentUser.name,
      currentUser.isChainAdmin,
      currentUser.chain?.name,
      currentUser.chain_organizations
    ]
  )

  if (!CANNY_APP_ID) return <>{children}</>

  return (
    <RealCannyProvider appId={CANNY_APP_ID} user={cannyUser}>
      {children}
    </RealCannyProvider>
  )
}

export default CannyProvider
