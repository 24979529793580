/**
 * System roles
 * @enum {string}
 * @readonly
 *
 * System roles are used to restrict access to certain pages or actions based on the user's role.
 * These roles are only used on organizations as of now. To check if a user is a chain admin, we can
 * check against the `chain_admin` boolean field on the current user object.
 *
 * There are also custom roles that users can define & add to their own user
 * accounts. These roles will take priority over the system roles.
 *
 * For example, if a user is a regular user, but they have a custom role that
 * gives them "write" access on an organizations settings, they will be able to
 * update those settings whilst the other pages will still be restricted to
 * them.
 */
export enum SystemRoles {
  ADMIN = 'admin',
  READ_ONLY = 'read_only',
  REGULAR = 'regular'
}
