import { Suspense, memo, useCallback, useEffect, useMemo } from 'react'

import { Alert, Box, Typography } from '@mui/material'

import Link from 'next/link'

import { usePathname, useRouter } from 'next/navigation'

import { ErrorBoundary } from '@sentry/nextjs'

import ErrorFallback from 'components/common/ErrorBoundary/ErrorFallback'
import FullPageLoader from 'components/FullPageLoader'

import SideNav from 'components/layout/SideNav/SideNav'
import TopNav from 'components/layout/TopNav/TopNav'
import useMainLayout from 'hooks/common/layout/useMainLayout'
import usePermissions from 'hooks/permissions/usePermissions'
import useErrorBoundary from 'hooks/useErrorBoundary'
import useResponsive from 'hooks/useResponsive'
import useChainContextStore from 'store/chainContext'
import Routes from 'types/enums/routes'

import { SIDE_NAV, TOP_NAV } from 'utils/constants/layout'

import type { ReactNode } from 'react'

interface ErrorFallbackProps {
  resetError: () => void
}

interface MainLayoutProps {
  children: ReactNode
}

const fullPageFallback = <FullPageLoader />

const mainContainerStyles = (isReady: boolean, lgUp: boolean, sideNavMini: boolean) => ({
  position: 'relative',
  height: '100%',
  margin: '0',
  display: 'flex',
  flexDirection: 'column',
  ...(isReady && {
    width: lgUp ? `calc(100% - ${sideNavMini ? SIDE_NAV.W_MINI : SIDE_NAV.W_VERTICAL}px)` : '100%',
    marginLeft: lgUp ? `${sideNavMini ? SIDE_NAV.W_MINI : SIDE_NAV.W_VERTICAL}px` : 0,
    paddingTop: `${TOP_NAV.H_DESKTOP}px`
  })
})

const MainLayoutContent = ({ children }: MainLayoutProps) => {
  const {
    isLoading,
    isAuthenticated,
    reviewAppUrl,
    showReviewAppAlert,
    hideReviewAppAlert,
    sideNavOpen,
    sideNavMini,
    toggleSideNav,
    toggleSideNavMini
  } = useMainLayout()
  const lgUp = useResponsive('up', 'lg')

  const { childrenBoundaryRef } = useErrorBoundary()

  const { selectedChain, selectedOrganization } = useChainContextStore((state) => ({
    selectedChain: state.selectedChain,
    selectedOrganization: state.selectedOrganization
  }))
  const { hasRoutePermissions } = usePermissions()
  const currentPath = usePathname()
  const router = useRouter()
  const isReady = useMemo<boolean>(
    () => Boolean(!isLoading && isAuthenticated && Boolean(selectedChain || selectedOrganization)),
    [isLoading, isAuthenticated, selectedChain, selectedOrganization]
  )

  // This is the global effect that handles routing logic base on the user's
  // authentication status, & selected context (chain & organization).
  useEffect(() => {
    if (!isReady || hasRoutePermissions(currentPath as Routes)) return

    const isSettingsPage = currentPath.startsWith(Routes.SETTINGS)

    let redirect = Routes.SETTINGS

    if (hasRoutePermissions(Routes.BOOKINGS)) redirect = Routes.BOOKINGS

    if (isSettingsPage) {
      redirect = Routes.SETTINGS
      if (hasRoutePermissions(Routes.SETTINGS_ORGANIZATIONS)) {
        redirect = Routes.SETTINGS_ORGANIZATIONS
      } else if (hasRoutePermissions(Routes.SETTINGS_GENERAL_SETTINGS)) {
        redirect = Routes.SETTINGS_GENERAL_SETTINGS
      }
    }

    router.push(redirect)
  }, [currentPath, hasRoutePermissions, isReady, router])

  const showPreviewAppAlert = useMemo(
    () => reviewAppUrl && showReviewAppAlert && isAuthenticated,
    [isAuthenticated, reviewAppUrl, showReviewAppAlert]
  )

  const renderTopNav = () => {
    if (!isReady) return null

    return <TopNav sideNavMini={sideNavMini} toggleSideNav={toggleSideNav} />
  }

  const renderSideNav = useCallback(() => {
    if (!isReady) return null

    return (
      <SideNav
        open={sideNavOpen}
        onClose={toggleSideNav}
        mini={sideNavMini}
        toggleSideNavMini={toggleSideNavMini}
      />
    )
  }, [isReady, sideNavMini, sideNavOpen, toggleSideNav, toggleSideNavMini])

  const renderPreviewAppUrl = useCallback(() => {
    if (!showPreviewAppAlert || !reviewAppUrl) return null

    return (
      <Alert severity="warning" onClose={hideReviewAppAlert}>
        <Typography color="inherit">Using an alternative API URL for this environment.</Typography>
        Backend API URL:{' '}
        <Link target="_blank" href={reviewAppUrl}>
          {reviewAppUrl}
        </Link>
      </Alert>
    )
  }, [hideReviewAppAlert, reviewAppUrl, showPreviewAppAlert])

  const errorBoundaryFallback = useCallback(
    ({ resetError }: ErrorFallbackProps) => {
      childrenBoundaryRef.current = resetError

      return <ErrorFallback resetError={resetError} />
    },
    [childrenBoundaryRef]
  )

  const containerStyles = mainContainerStyles(isReady, lgUp, sideNavMini)

  return (
    <>
      <Suspense fallback={null}>{renderTopNav()}</Suspense>
      <Box height="100%">
        <Suspense fallback={null}>{renderSideNav()}</Suspense>

        <Box sx={containerStyles}>
          <Suspense fallback={fullPageFallback}>
            {renderPreviewAppUrl()}

            {/* Error boundary wraps around main app so users can navigate away if the app crashes */}
            <ErrorBoundary fallback={errorBoundaryFallback}>{children}</ErrorBoundary>
          </Suspense>
        </Box>
      </Box>
    </>
  )
}

export default memo(MainLayoutContent)
