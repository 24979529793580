import { useMemo } from 'react'

import {
  Apartment,
  Block,
  Bookmark,
  CalendarMonth,
  Description,
  Money,
  RequestQuote,
  Task
} from '@mui/icons-material'

import { useTranslation } from 'next-i18next'

import { TranslationNamespaces } from 'types/enums/tNamespaces'

import type SetupValueType from '@repo/et-types/setupValueType'

const filterDisabled = (options: SetupValueType[]) => options.filter((option) => !option.disabled)

const useSetupValueOptions = () => {
  const { t } = useTranslation([TranslationNamespaces.COMMON, TranslationNamespaces.SETUP_VALUES])

  const options = useMemo<SetupValueType[]>(
    () => [
      {
        id: 1,
        field_name: t('revenueCategories'),
        field_value: 'revenue_categories',
        used_for: [t('menuItems'), t('lineItems')],
        type: 'setup_values',
        icon: <Money />
      },
      {
        id: 2,
        field_name: t('referralSources'),
        field_value: 'referral_sources',
        used_for: [t('bookings'), t('groups'), t('accounts')],
        type: 'setup_values',
        icon: <Bookmark />
      },
      {
        id: 3,
        field_name: t(`${TranslationNamespaces.SETUP_VALUES}:lostReasons`),
        field_value: 'lost_reasons',
        used_for: [t('bookings')],
        type: 'setup_values',
        icon: <Bookmark />
      },
      {
        id: 4,
        field_name: t(`${TranslationNamespaces.SETUP_VALUES}:bookingTypes`),
        field_value: 'booking_types',
        used_for: [t('bookings')],
        type: 'setup_values',
        icon: <Bookmark />
      },
      {
        id: 5,
        field_name: t(`${TranslationNamespaces.SETUP_VALUES}:taskTypes`),
        field_value: 'task_types',
        used_for: [t('tasks')],
        type: 'setup_values',
        icon: <Task />
      },
      {
        id: 6,
        field_name: t(`${TranslationNamespaces.SETUP_VALUES}:eventTypes`),
        field_value: 'event_types',
        used_for: [t('events')],
        type: 'setup_values',
        icon: <CalendarMonth />
      },
      {
        id: 7,
        field_name: t(`${TranslationNamespaces.SETUP_VALUES}:eventSetupStyles`),
        field_value: 'setup_styles',
        used_for: [t('events')],
        type: 'setup_values',
        icon: <CalendarMonth />
      },
      {
        id: 8,
        field_name: t(`${TranslationNamespaces.SETUP_VALUES}:accountTypes`),
        field_value: 'account_types',
        used_for: [t('accounts')],
        type: 'setup_values',
        icon: <Apartment />
      },
      {
        id: 9,
        field_name: t(`${TranslationNamespaces.SETUP_VALUES}:marketSegments`),
        field_value: 'market_segments',
        used_for: [t('accounts')],
        type: 'setup_values',
        icon: <Apartment />
      },
      {
        id: 10,
        field_name: t(`${TranslationNamespaces.SETUP_VALUES}:documentTypes`),
        field_value: 'document_types',
        used_for: [t('documents')],
        type: 'setup_values',
        icon: <Description />
      },
      {
        id: 11,
        field_name: t(`${TranslationNamespaces.SETUP_VALUES}:paymentTypes`),
        field_value: 'payment_types',
        used_for: [t('invoices')],
        type: 'setup_values',
        icon: <RequestQuote />
      },
      {
        id: 12,
        field_name: t(`${TranslationNamespaces.SETUP_VALUES}:spaceClosureReasons`),
        field_value: 'space_closure_reasons',
        used_for: [t('spaces')],
        type: 'setup_values',
        icon: <Block />
      }
    ],
    [t]
  )

  return filterDisabled(options)
}

export default useSetupValueOptions
