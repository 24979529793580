class Profitwell {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  profitwell: any

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(profitwell: any) {
    this.profitwell = profitwell
  }

  initialize(user_id: string | number) {
    this.profitwell('start', { user_id })
  }

  invoke(method: string, ...args: unknown[]) {
    this.profitwell(method, ...args)
  }
}

export default Profitwell
