import { OrganizationFeatures } from 'types/enums/featureFlags'
import Routes from 'types/enums/routes'
import { ApplicationScopes } from 'types/enums/scopes'

import { mainAppFeatureFlags } from 'utils/routeConfig/mainAppConfig'

import type { RoutesConfig } from 'types/routes'

const reportsFlags = [...mainAppFeatureFlags]

const reportsRouteConfig: RoutesConfig = {
  [Routes.REPORTS]: {
    featureFlags: reportsFlags,
    scope: ApplicationScopes.ALL
  },
  [Routes.REPORTS_ACCOUNT_LIST]: {
    featureFlags: reportsFlags,
    scope: ApplicationScopes.ALL
  },
  [Routes.REPORTS_ACTIVITY_LIST]: {
    featureFlags: reportsFlags,
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.REPORTS_AUTOMATION_STATS]: {
    featureFlags: reportsFlags,
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.REPORTS_BOOKING_FINANCIAL]: {
    featureFlags: reportsFlags,
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.REPORTS_BOOKING_GRAPHS]: {
    featureFlags: reportsFlags,
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.REPORTS_BOOKING_LIST]: {
    featureFlags: reportsFlags,
    scope: ApplicationScopes.ALL
  },
  [Routes.REPORTS_CONTACT_LIST]: {
    featureFlags: reportsFlags,
    scope: ApplicationScopes.ALL
  },
  [Routes.REPORTS_EMAIL_TEMPLATE_PERFORMANCE]: {
    featureFlags: reportsFlags,
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.REPORTS_GROUP_LIST]: {
    featureFlags: [...reportsFlags, OrganizationFeatures.GRC],
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.REPORTS_GROUP_PACE]: {
    featureFlags: [...reportsFlags, OrganizationFeatures.GRC],
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.REPORTS_GROUP_SALES_PRODUCTIVITY]: {
    featureFlags: [...reportsFlags, OrganizationFeatures.GRC],
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.REPORTS_LINE_ITEM_QUANTITY]: {
    featureFlags: reportsFlags,
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.REPORTS_LINE_ITEM_LIST]: {
    featureFlags: reportsFlags,
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.REPORTS_DAILY_EVENTS]: {
    featureFlags: reportsFlags,
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.REPORTS_DAILY_EVENTS_WITH_DETAILS]: {
    featureFlags: reportsFlags,
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.REPORTS_EVENT_LIST]: {
    featureFlags: reportsFlags,
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.REPORTS_EVENT_SALES_PRODUCTIVITY]: {
    featureFlags: reportsFlags,
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.REPORTS_GROUP_PICKUP_BY_SALES_PERSON]: {
    featureFlags: [...reportsFlags, OrganizationFeatures.GRC],
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.REPORTS_TOP_ACCOUNTS]: {
    featureFlags: reportsFlags,
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.REPORTS_TOP_KEY_ACCOUNTS]: {
    featureFlags: reportsFlags,
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.REPORTS_CATERING_PACE]: {
    featureFlags: reportsFlags,
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.REPORTS_INVOICE_LIST]: {
    featureFlags: reportsFlags,
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.REPORTS_PAYMENT_LIST]: {
    featureFlags: reportsFlags,
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.REPORTS_TASK_GRAPH]: {
    featureFlags: reportsFlags,
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.REPORTS_TASK_LIST]: {
    featureFlags: reportsFlags,
    scope: ApplicationScopes.ALL
  },
  [Routes.REPORTS_TASK_PRODUCTIVITY]: {
    featureFlags: reportsFlags,
    scope: ApplicationScopes.ORGANIZATION
  }
}

export default reportsRouteConfig
