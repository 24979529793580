export enum FeatureType {
  CRM = 'crm',
  SETTINGS = 'settings'
}

export enum CrudPermission {
  READ = 'readable',
  CREATE = 'creatable',
  UPDATE = 'updatable',
  DELETE = 'deletable'
}
