import { useMemo } from 'react'

import { Box, Divider, MenuItem, Typography } from '@mui/material'

import { DateFormat } from '@repo/utils/datetime'
import { capitalize } from 'lodash'

import styles from 'components/common/GlobalSearch/GlobalSearch.module.scss'
import useDateTime from 'hooks/common/dateTime'

import type { Address } from '@repo/et-types'
import type { GlobalSearchResult } from 'types/GlobalSearchResult'

const buildAddress = (address: Address) => {
  const { city, state, country } = address

  return [city, state, country].filter(Boolean).join(', ')
}

const SearchResult = ({
  results,
  onClick
}: {
  results: GlobalSearchResult[]
  onClick: (item: GlobalSearchResult) => void
}) => {
  const { getFormattedDateRange } = useDateTime()

  const mappedResults = useMemo(
    () =>
      results.reduce(
        (acc, res) => {
          if (!acc[res.type]) {
            acc[res.type] = []
          }

          acc[res.type].push(res)
          return acc
        },
        {} as Record<string, GlobalSearchResult[]>
      ),
    [results]
  )

  const captionMap = {
    account: (item: GlobalSearchResult) => item.address && buildAddress(item.address as Address),
    contact: (item: GlobalSearchResult) => item.email,
    booking: (item: GlobalSearchResult) =>
      item.start_date &&
      item.end_date &&
      getFormattedDateRange({
        startDate: item.start_date,
        endDate: item.end_date,
        dateFormat: DateFormat.MMM_d_YYYY
      })
  }

  return Object.entries(mappedResults).map(([key, value], index) => (
    <Box key={key}>
      <Box className={styles.globalSearch__results}>
        <Typography variant="subtitle2">{capitalize(key)}</Typography>
        {value.map((item: GlobalSearchResult) => (
          <MenuItem key={item.id} onClick={() => onClick(item)}>
            <Box display="block">
              <Typography variant="body2">{item.name}</Typography>
              <Typography color="text.secondary" variant="caption">
                {captionMap[item.type]?.(item)}
              </Typography>
            </Box>
          </MenuItem>
        ))}
      </Box>
      {index < value.length && <Divider />}
    </Box>
  ))
}

export default SearchResult
