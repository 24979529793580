import { CorporateFare } from '@mui/icons-material'
import { Avatar } from '@mui/material'

type MultiOrgIconProps = {
  className?: string
}

const MultiOrgIcon = ({ className }: MultiOrgIconProps) => (
  <Avatar className={className}>
    <CorporateFare color="action" fontSize="inherit" />
  </Avatar>
)

export default MultiOrgIcon
