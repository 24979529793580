import { useCallback } from 'react'

import { useMutation } from '@tanstack/react-query'

import { useSessionStorage, useToggle } from 'usehooks-ts'

import useNavigation from 'hooks/common/useNavigation'
import useResponsive from 'hooks/useResponsive'
import useUserSettingResource from 'hooks/userSettings/useUserSettingsResource'
import useScreenSizes from 'hooks/useScreenSizes'
import useCurrentUserStore from 'store/currentUser'
import { ET_REVIEW_APP_API_URL_KEY } from 'utils/constants/global'

import type { UserSetting } from '@repo/et-types'

const isBrowser = typeof window !== 'undefined'
const showReviewAppAlertKey = 'showReviewAppAlert'

type useMainLayoutReturnValue = {
  sideNavOpen: boolean
  sideNavMini: boolean
  toggleSideNav: () => void
  toggleSideNavMini: () => void
  isLoading: boolean
  isAuthenticated: boolean
  reviewAppUrl: string | null
  showReviewAppAlert: boolean
  hideReviewAppAlert: () => void
}

const useMainLayout = (): useMainLayoutReturnValue => {
  const [showReviewAppAlert, setShowReviewAppAlert] = useSessionStorage(showReviewAppAlertKey, true)

  const { isLoading, isAuthenticated, userSetting, updateCurrentUser } = useCurrentUserStore(
    (state) => ({
      isAuthenticated: state.isAuthenticated,
      isLoading: state.isLoading,
      userSetting: state.user_setting,
      updateCurrentUser: state.updateCurrentUser
    })
  )
  const { isSmallScreen } = useScreenSizes()
  const userSettingResource = useUserSettingResource()

  const { isSettingNav } = useNavigation()
  const lgUp = useResponsive('up', 'lg')
  const [sideNavOpen, toggleSideNav] = useToggle(false)
  const [sideNavMini, toggleSideNavMini] = useToggle(userSetting?.sidebar_folded ?? false)

  const { mutateAsync: sideBarStateMutation } = useMutation(async (sideBarFolded: boolean) => {
    userSettingResource.update({ id: userSetting?.id, sidebar_folded: sideBarFolded })
    return updateCurrentUser({
      user_setting: { ...userSetting, sidebar_folded: sideBarFolded } as UserSetting
    })
  })

  // used for pointing to review apps
  const reviewAppUrl = isBrowser ? localStorage.getItem(ET_REVIEW_APP_API_URL_KEY) : ''

  const hideReviewAppAlert = () => setShowReviewAppAlert(false)

  const doToggle = useCallback(async () => {
    if (!isSmallScreen) await sideBarStateMutation(!sideNavMini)

    return toggleSideNavMini()
  }, [sideNavMini, sideBarStateMutation, toggleSideNavMini, isSmallScreen])

  return {
    sideNavOpen,
    sideNavMini: isSettingNav || !lgUp ? false : sideNavMini,
    toggleSideNav,
    toggleSideNavMini: doToggle,
    isLoading,
    isAuthenticated,
    reviewAppUrl,
    showReviewAppAlert,
    hideReviewAppAlert
  }
}

export default useMainLayout
