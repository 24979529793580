import { addSeconds, format, isValid } from 'date-fns'

import { DateFormat, DateTimeFormat, TimeFormat } from './enums'

import type {
  GetFormattedDateParams,
  GetFormattedDateRangeParams,
  GetFormattedDateTimeParams,
  GetFormattedDateTimeRangeParams,
  GetFormattedStartEndDateParams,
  GetFormattedTimeParams
} from './types'

import { getParsedDate, getUTCDate } from './utils'
import { PickersTimezone } from '@mui/x-date-pickers-pro'
import { toZonedTime, fromZonedTime } from 'date-fns-tz'
import { DEFAULT_TIME_ZONE } from './constants'

export const getUtcDateInOrgTimeZone = (
  date: Date | string,
  orgTimezone?: PickersTimezone | null
) => toZonedTime(date, orgTimezone ? orgTimezone : DEFAULT_TIME_ZONE)

export const getOrgTimeZoneDateInUtc = (
  date: Date | string,
  orgTimezone?: PickersTimezone | null
) => fromZonedTime(date, orgTimezone ? orgTimezone : DEFAULT_TIME_ZONE)

export const getFormattedDate = ({
  locale,
  date,
  dateFormat = DateFormat.MMMM_do_YYYY,
  isUTC = false
}: GetFormattedDateParams) => {
  const parsedDate = getParsedDate(date, isUTC)

  if (!parsedDate || !isValid(parsedDate)) return

  return format(parsedDate, dateFormat, { locale })
}

export const getFormattedDateRange = ({
  startDate,
  endDate,
  dateFormat = DateFormat.EEE_MMM_d_yyyy,
  locale,
  isUTC = false
}: GetFormattedDateRangeParams) => {
  const formattedStartDate = getFormattedDate({
    date: startDate,
    dateFormat,
    locale,
    isUTC
  })

  const formattedEndDate = getFormattedDate({
    date: endDate,
    dateFormat,
    locale,
    isUTC
  })

  const prefix = formattedStartDate ?? ''
  const suffix = formattedEndDate ?? ''
  const separator = prefix && suffix ? ' - ' : ''

  return `${prefix}${separator}${suffix}`
}

export const getFormattedTime = ({
  use24HourClock,
  locale,
  date,
  seconds,
  timeFormat = TimeFormat.h_mm_ss_a,
  isUTC = true // time is always in UTC
}: GetFormattedTimeParams) => {
  let parsedDate

  if (date) {
    parsedDate = getParsedDate(date, false)
  } else {
    parsedDate = isUTC ? getUTCDate(new Date(0)) : new Date(0)
    parsedDate.setUTCSeconds(seconds ?? 0)
  }

  if (!parsedDate || !isValid(parsedDate)) return

  const currentTimeFormat = use24HourClock ? TimeFormat.H_mm_ss : timeFormat

  return format(parsedDate, currentTimeFormat, { locale })
}

export const getFormattedDateTime = ({
  date,
  locale,
  use24HourClock,
  seconds,
  dateTimeFormat = DateTimeFormat.EEE_MMM_d_yyyy_h_mm_a,
  isUTC = false
}: GetFormattedDateTimeParams) => {
  const parsedDate = getParsedDate(date, isUTC)

  if (!parsedDate) return

  if (seconds || seconds === 0) {
    parsedDate.setSeconds(seconds)
  }

  if (!isValid(parsedDate)) return

  const currentDateTimeFormat = use24HourClock ? DateTimeFormat.EEE_MMM_d_yyyy_H_mm : dateTimeFormat

  return format(parsedDate, currentDateTimeFormat, { locale })
}

export const getFormattedDateTimeRange = ({
  startDate,
  startTime,
  endDate,
  locale,
  use24HourClock,
  endTime,
  dateTimeFormat = DateTimeFormat.MMM_d_yyyy_h_mm_a,
  isUTC = false
}: GetFormattedDateTimeRangeParams) => {
  const formattedStartAt = getFormattedDateTime({
    date: startDate,
    seconds: startTime,
    dateTimeFormat,
    locale,
    use24HourClock,
    isUTC
  })

  const formattedEndAt = getFormattedDateTime({
    date: endDate,
    seconds: endTime,
    dateTimeFormat,
    locale,
    use24HourClock,
    isUTC
  })

  const prefix = formattedStartAt ?? ''
  const suffix = formattedEndAt ?? ''
  const separator = prefix && suffix ? ' - ' : ''

  return `${prefix}${separator}${suffix}`
}

export const getFormattedStartEndDate = (record: GetFormattedStartEndDateParams) => {
  let { startDate, endDate, allDay, locale, use24HourClock, allDayText } = record
  let prefix, suffix

  const isSameDay = getParsedDate(startDate)?.getDate() === getParsedDate(endDate)?.getDate()

  if (allDay) {
    prefix = isSameDay
      ? getFormattedDate({ date: startDate, locale })
      : getFormattedDateRange({ startDate, endDate, locale })
    suffix = allDayText ?? `(All Day)`
  } else {
    prefix = isSameDay
      ? getFormattedDateTime({ date: startDate, locale, use24HourClock })
      : getFormattedDateTimeRange({ startDate, endDate, locale, use24HourClock })
    suffix = isSameDay
      ? ` - ${getFormattedTime({ date: endDate, timeFormat: TimeFormat.h_mm_a, locale, use24HourClock })}`
      : ''
  }

  return `${prefix} ${suffix}`
}
