import { useEffect } from 'react'

import { hotjar } from 'react-hotjar'

import useChainContextStore from 'store/chainContext'

import useCurrentUserStore from 'store/currentUser'

const useInitializeHotJarUser = () => {
  const currentUser = useCurrentUserStore()
  const selectedOrganization = useChainContextStore((state) => state.selectedOrganization)

  useEffect(() => {
    if (!currentUser?.id || !hotjar.initialized()) return

    hotjar.identify(String(currentUser.id), {
      Country: selectedOrganization?.address?.country,
      'User Created At': currentUser.created_at,
      'User Email': currentUser.email,
      'Chain ID': currentUser.chain?.id,
      'Chain Name': currentUser.chain?.name,
      'Chain Created At': currentUser.chain?.created_at,
      'Organization ID': selectedOrganization?.id,
      'Organization Name': selectedOrganization?.name,
      'Organization Created At': selectedOrganization?.created_at,
      'Vendor Type': selectedOrganization?.vendor_type
    })
  }, [
    currentUser?.chain?.created_at,
    currentUser?.chain?.id,
    currentUser?.chain?.name,
    currentUser?.created_at,
    currentUser?.email,
    currentUser?.id,
    selectedOrganization?.address?.country,
    selectedOrganization?.created_at,
    selectedOrganization?.id,
    selectedOrganization?.name,
    selectedOrganization?.vendor_type
  ])
}

export default useInitializeHotJarUser
