import { usePathname } from 'next/navigation'

import Routes from 'types/enums/routes'

/**
 * Custom hook for handling navigation-related logic.
 *
 * @returns An object containing the current path and boolean values indicating if the current path starts with certain routes.
 */
const useNavigation = () => {
  const currentPath = usePathname()

  const isLoginPage = currentPath?.startsWith(Routes.AUTH_LOGIN)
  const isSettingNav = currentPath?.startsWith(Routes.SETTINGS)
  const isReportsNav = currentPath?.startsWith(Routes.REPORTS)

  return {
    currentPath,
    isSettingNav,
    isReportsNav,
    isLoginPage
  }
}

export default useNavigation
