import { useCallback, useMemo, useState } from 'react'

import { Box, CardHeader, MenuItem, Select } from '@mui/material'

import clsx from 'clsx'

import { useTranslation } from 'next-i18next'

import OptimizedAvatar from 'components/common/avatar/OptimizedAvatar'
import MultiOrgIcon from 'components/layout/MultiOrgIcon'
import styles from 'components/pickers/GlobalContextPicker.module.scss'
import GlobalContextPickerItem from 'components/pickers/GlobalContextPickerItem'

import { SearchInput } from 'components/views/filters/SearchInput'
import usePermissions from 'hooks/permissions/usePermissions'
import useChainContextStore from 'store/chainContext'
import useCurrentUserStore from 'store/currentUser'

import type { SelectClasses, SxProps } from '@mui/material'

import type { Chain, Organization } from '@repo/et-types'

const SearchItemStyles: SxProps = {
  backgroundColor: 'white',
  '&:hover': { backgroundColor: 'transparent' },
  '&.Mui-focusVisible': { backgroundColor: 'transparent' },
  cursor: 'default',
  position: 'sticky',
  top: 0,
  left: 0,
  zIndex: 100
}

const Search = ({ setSearch, search }: { setSearch: (value: string) => void; search: string }) => (
  <MenuItem
    onKeyDown={(e) => e.stopPropagation()}
    disableRipple
    disableTouchRipple
    sx={SearchItemStyles}>
    <SearchInput onChange={(value) => setSearch(value)} value={search} />
  </MenuItem>
)

// Ensure the search input is not included in the menus selection highlight.
Search.muiSkipListHighlight = true

interface GlobalContextPickerProps {
  classes?: Partial<SelectClasses>
  onSelect?: (item: Organization | Chain) => void
}

const GlobalContextPicker = ({ classes, onSelect }: GlobalContextPickerProps): JSX.Element => {
  const { t } = useTranslation()
  const [search, setSearch] = useState('')
  const { setSelectedChain, setSelectedOrganization, selectedOrganization } = useChainContextStore(
    (state) => ({
      selectedOrganization: state.selectedOrganization,
      selectedChain: state.selectedChain,
      setSelectedChain: state.setSelectedChain,
      setSelectedOrganization: state.setSelectedOrganization
    })
  )
  const { canViewChainContext } = usePermissions()
  const { chain, organizations } = useCurrentUserStore((state) => ({
    chain: state.chain,
    organizations: state.organizations
  }))

  const onItemSelect = useCallback(
    (item: Organization | Chain) => {
      onSelect?.(item)
      const isOrganization = item.type === 'organization'

      if (!isOrganization) setSelectedChain(item as Chain)
      else setSelectedOrganization(item as Organization)

      return setSearch('')
    },
    [onSelect, setSelectedChain, setSelectedOrganization]
  )

  const itemsData = useMemo(() => {
    let data: (Organization | Chain)[] = [...organizations]

    if (canViewChainContext) data.unshift(chain as Chain)

    if (search.length > 0) {
      data = data.filter((item: Chain | Organization) =>
        item.name.toLowerCase().includes(search.toLowerCase())
      )
    }

    return data
  }, [chain, organizations, search, canViewChainContext])

  const items = useMemo(() => {
    const selectItems = itemsData.map((item) => (
      <GlobalContextPickerItem
        key={`${item.type}-${item.id}`}
        item={item}
        onClick={() => onItemSelect(item)}
      />
    ))

    return selectItems
  }, [onItemSelect, itemsData])

  return (
    <Box display="flex" flexDirection="column" rowGap={1} width="100%">
      <Select
        fullWidth
        displayEmpty
        classes={{ outlined: clsx(styles.select, classes?.select) }}
        MenuProps={{ className: styles.menu }}
        renderValue={() => (
          <CardHeader
            classes={{ root: styles.header, avatar: styles.avatar, title: styles.title }}
            subheaderTypographyProps={{ variant: 'body2' }}
            titleTypographyProps={{ variant: 'body1' }}
            avatar={
              selectedOrganization ? (
                <OptimizedAvatar
                  className={styles.orgIcon}
                  src={selectedOrganization.logo_thumb_url || undefined}
                />
              ) : (
                <MultiOrgIcon className={styles.orgIcon} />
              )
            }
            title={selectedOrganization?.name || t('chain')}
          />
        )}>
        <Search setSearch={setSearch} search={search} />
        {items}
      </Select>
    </Box>
  )
}

export default GlobalContextPicker
