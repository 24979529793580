import { useEffect } from 'react'

import { useSearchParams } from 'next/navigation'

import FullPageLoader from 'components/FullPageLoader'
import useNavigation from 'hooks/common/useNavigation'

import usePermissions from 'hooks/permissions/usePermissions'
import useChainContextStore from 'store/chainContext'

import useCurrentUserStore from 'store/currentUser'

import type { ReactNode } from 'react'

interface GlobalContextProviderProps {
  children: ReactNode
}

/**
 * The GlobalContextProvider is a wrapper component that loads the current chain
 * or organization when the application mounts. If there is no chain or
 * organization selected, it will determine which one to select based on the
 * user's permissions/access.
 * @component
 */
const GlobalContextProvider = ({ children }: GlobalContextProviderProps): JSX.Element => {
  const {
    persistedChainId,
    persistedOrganizationId,
    selectedOrganization,
    selectedChain,
    setSelectedChain,
    setSelectedOrganization
  } = useChainContextStore((state) => ({
    selectedOrganization: state.selectedOrganization,
    selectedChain: state.selectedChain,
    setSelectedChain: state.setSelectedChain,
    setSelectedOrganization: state.setSelectedOrganization,
    persistedChainId: state.persistedChainId,
    persistedOrganizationId: state.persistedOrganizationId
  }))
  const { isLoginPage } = useNavigation()
  const searchParams = useSearchParams()
  const chainId = searchParams.get('chain_id')
  const organizationId = searchParams.get('organization_id')

  const { chain, organizations } = useCurrentUserStore((state) => ({
    chain: state.chain,
    organizations: state.organizations
  }))

  const { canViewChainContext } = usePermissions()

  useEffect(() => {
    const validContext = (selectedChain && canViewChainContext) || selectedOrganization

    if (validContext) return

    // Handle URL params for setting the global context on app load.
    if (chainId && chainId === chain?.id.toString() && canViewChainContext) {
      setSelectedChain(chain)
      return
    } else if (organizationId) {
      const org = organizations.find((o) => o.id.toString() === organizationId)

      if (org) {
        setSelectedOrganization(org)
        return
      }
    }

    // If the page is reloaded, check if there is a persisted chain or organization
    if (persistedChainId && canViewChainContext) {
      setSelectedChain(chain)
      return
    } else if (persistedOrganizationId) {
      setSelectedOrganization(organizations.find((o) => o.id === persistedOrganizationId))
      return
    }

    // Select the first organization by default.
    setSelectedOrganization(organizations[0])
  }, [
    organizations,
    selectedOrganization,
    setSelectedOrganization,
    persistedChainId,
    persistedOrganizationId,
    canViewChainContext,
    chain,
    selectedChain,
    setSelectedChain,
    chainId,
    organizationId
  ])

  if (!selectedOrganization && !selectedChain && !isLoginPage) return <FullPageLoader />

  return <>{children}</>
}

export default GlobalContextProvider
