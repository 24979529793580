import { useMemo } from 'react'

import { Grow, Paper, Popper, Stack } from '@mui/material'

import SideNavItem from 'components/layout/SideNav/SideNavItem'

import type { Theme } from '@mui/material/styles'
import type { TransitionHandlerProps } from '@mui/material/transitions'

import type { SideNavItem as SideNavItemType } from 'hooks/common/layout/sideNav/useSideNav'
import type { MouseEvent } from 'react'

type SideNavPopperProps = {
  anchorEl: HTMLElement | null
  items: SideNavItemType[]
  onMouseEnter: (e: MouseEvent<HTMLDivElement>) => void
  onMouseLeave: () => void
}

type RenderPopperProps = {
  TransitionProps?: TransitionHandlerProps
}

const paperStyles = { boxShadow: (theme: Theme) => theme?.customShadows?.dropdown }

const SideNavPopper = ({ anchorEl, items, onMouseEnter, onMouseLeave }: SideNavPopperProps) => {
  const popperItems = useMemo(
    () => items?.map((item, index) => <SideNavItem key={index} {...item} />),
    [items]
  )

  const renderPopperChildren = ({ TransitionProps }: RenderPopperProps) => (
    <Grow {...TransitionProps}>
      <Paper onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} sx={paperStyles}>
        <Stack>{popperItems}</Stack>
      </Paper>
    </Grow>
  )

  return (
    <Popper anchorEl={anchorEl} open={Boolean(anchorEl)} placement="right-start" transition>
      {renderPopperChildren}
    </Popper>
  )
}

export default SideNavPopper
