const loadScript = `
(function(i,s,o,g,r,a,m){i[o]=i[o]||function(){(i[o].q=i[o].q||[]).push(arguments)};
a=s.createElement(g);m=s.getElementsByTagName(g)[0];a.async=1;a.src=r+'?auth='+
s.getElementById(o+'-js').getAttribute('data-pw-auth');m.parentNode.insertBefore(a,m);
})(window,document,'profitwell','script','https://public.profitwell.com/js/profitwell.js');
`

class ProfitwellLoader {
  /**
   * Get profitwell instance
   */
  get profitwell() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (window as any).profitwell
  }

  /**
   * Load Profitwell script
   */
  async load(appToken: string) {
    // If profitwell is already loaded, return that instance.
    if (this.profitwell) return this.profitwell

    // Load profitwell script
    const script = document.createElement('script')

    script.type = 'text/javascript'
    script.async = true
    script.id = 'profitwell-js'
    script.setAttribute('data-pw-auth', appToken)
    script.innerHTML = loadScript

    return new Promise((resolve, reject) => {
      script.onload = () => resolve(this.profitwell)

      script.onerror = (error) => reject(error)

      document.head.appendChild(script)
    })
  }
}

export default ProfitwellLoader
