import mainAppRouteConfig from 'utils/routeConfig/mainAppConfig'
import publicRouteConfig from 'utils/routeConfig/publicConfig'
import reportsRouteConfig from 'utils/routeConfig/reportsConfig'
import settingsRouteConfig from 'utils/routeConfig/settingsConfig'

const routeConfig = {
  ...mainAppRouteConfig,
  ...reportsRouteConfig,
  ...settingsRouteConfig,
  ...publicRouteConfig
}

export default routeConfig
