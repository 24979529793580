import { useCallback } from 'react'

import {
  DEFAULT_TIME_ZONE,
  getFormattedDate as getFormattedDateHelper,
  getFormattedDateRange as getFormattedDateRangeHelper,
  getFormattedDateTime as getFormattedDateTimeHelper,
  getFormattedDateTimeRange as getFormattedDateTimeRangeHelper,
  getFormattedStartEndDate as getFormattedStartEndDateHelper,
  getFormattedTime as getFormattedTimeHelper
} from '@repo/utils/datetime'

import { fromZonedTime, toZonedTime } from 'date-fns-tz'

import { useTranslation } from 'next-i18next'

import useDateFnsLocale from 'hooks/useDateFnsLocale'

import useChainContextStore from 'store/chainContext'

import type { PickersTimezone } from '@mui/x-date-pickers-pro'
import type {
  GetFormattedDateParams,
  GetFormattedDateRangeParams,
  GetFormattedDateTimeParams,
  GetFormattedDateTimeRangeParams,
  GetFormattedStartEndDateParams,
  GetFormattedTimeParams,
  UseDateTimeReturn
} from '@repo/utils/datetime'

const useDateTime = (): UseDateTimeReturn => {
  const { t } = useTranslation()
  const currentOrg = useChainContextStore((state) => state.selectedOrganization)
  const use24HourClock = currentOrg?.organization_setting?.use24_hour_time
  const currentLocale = useDateFnsLocale()

  const getUtcDateInOrgTimeZone = useCallback(
    (date: Date | string, orgTimezone?: PickersTimezone | null) =>
      toZonedTime(date, orgTimezone ? orgTimezone : (currentOrg?.time_zone ?? DEFAULT_TIME_ZONE)),
    [currentOrg?.time_zone]
  )

  const getOrgTimeZoneDateInUtc = useCallback(
    (date: Date | string, orgTimezone?: PickersTimezone | null) =>
      fromZonedTime(date, orgTimezone ? orgTimezone : (currentOrg?.time_zone ?? DEFAULT_TIME_ZONE)),
    [currentOrg?.time_zone]
  )

  const getFormattedDate = useCallback(
    ({ locale, ...params }: GetFormattedDateParams) =>
      getFormattedDateHelper({ locale: locale ?? currentLocale, ...params }),
    [currentLocale]
  )

  const getFormattedDateRange = useCallback(
    ({ locale, ...params }: GetFormattedDateRangeParams) =>
      getFormattedDateRangeHelper({ locale: locale ?? currentLocale, ...params }),
    [currentLocale]
  )

  const getFormattedTime = useCallback(
    ({ locale, ...params }: GetFormattedTimeParams) =>
      getFormattedTimeHelper({ locale: locale ?? currentLocale, use24HourClock, ...params }),
    [currentLocale, use24HourClock]
  )

  const getFormattedDateTime = useCallback(
    ({ locale, ...params }: GetFormattedDateTimeParams) =>
      getFormattedDateTimeHelper({
        locale: locale ?? currentLocale,
        use24HourClock,
        ...params
      }),
    [currentLocale, use24HourClock]
  )

  const getFormattedDateTimeRange = useCallback(
    ({ locale, ...params }: GetFormattedDateTimeRangeParams) =>
      getFormattedDateTimeRangeHelper({
        locale: locale ?? currentLocale,
        use24HourClock,
        ...params
      }),
    [currentLocale, use24HourClock]
  )

  const getFormattedStartEndDate = useCallback(
    (record: GetFormattedStartEndDateParams) =>
      getFormattedStartEndDateHelper({
        locale: currentLocale,
        use24HourClock,
        allDayText: t('allDay'),
        ...record
      }),
    [currentLocale, t, use24HourClock]
  )

  return {
    getUtcDateInOrgTimeZone,
    getOrgTimeZoneDateInUtc,
    getFormattedDate,
    getFormattedDateRange,
    getFormattedTime,
    getFormattedDateTime,
    getFormattedDateTimeRange,
    getFormattedStartEndDate
  }
}

export default useDateTime
