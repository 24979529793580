import { Grid, Typography } from '@mui/material'

import { useTranslation } from 'next-i18next'

import CustomErrorRenderer from 'components/common/CustomErrorRenderer'
import { StaticAssets } from 'types/enums/staticAssets'
import { TranslationNamespaces } from 'types/enums/tNamespaces'

const Custom500 = () => {
  const { t } = useTranslation([TranslationNamespaces.COMMON])

  return (
    <CustomErrorRenderer>
      <Grid container item direction="column" xs={12} textAlign="center" rowSpacing={1}>
        <Grid item>
          <Typography variant="h3">
            {t(`${TranslationNamespaces.COMMON}:serverError.title`)}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1" color="text.secondary">
            {t(`${TranslationNamespaces.COMMON}:serverError.description`)}
          </Typography>
        </Grid>
      </Grid>

      <Grid item display="block" width="90%">
        <img src={StaticAssets.SERVER_ERROR} alt="server-error" />
      </Grid>
    </CustomErrorRenderer>
  )
}

export default Custom500
