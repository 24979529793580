import { AppCacheProvider } from '@mui/material-nextjs/v14-pagesRouter'

import { ModalProvider } from 'mui-modal-provider'

import QueryProvider from 'providers/QueryProvider'
import UiProvider from 'providers/UiProvider'

import type { ReactNode } from 'react'

const BaseProviders = ({ children, ...pageProps }: { children: ReactNode }) => (
  <AppCacheProvider {...pageProps}>
    <QueryProvider>
      <UiProvider>
        <ModalProvider>{children}</ModalProvider>
      </UiProvider>
    </QueryProvider>
  </AppCacheProvider>
)

export default BaseProviders
