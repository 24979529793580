import { HTTPMethod } from '@repo/et-types/common/api'
import { useQuery } from '@tanstack/react-query'

import api from 'utils/api'

import type {
  ApiQueryParams,
  ApiResponse,
  QueryParams,
  QueryResponse
} from '@repo/et-types/common/api'
import type { GlobalSearchResult } from 'types/GlobalSearchResult'

const pathName = '/v2/search'

const getAll = async (params?: ApiQueryParams): Promise<ApiResponse<GlobalSearchResult[]>> => {
  const res = await api.request<ApiResponse<GlobalSearchResult[]>>({
    method: HTTPMethod.GET,
    url: pathName,
    params: params
  })

  return res.data
}

const useGlobalSearch = ({
  enabled,
  queryParams
}: QueryParams<GlobalSearchResult[]>): QueryResponse<GlobalSearchResult[]> => {
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: [queryParams],
    enabled,
    queryFn: () => getAll(queryParams)
  })

  return {
    data: (data?.data as unknown as GlobalSearchResult[]) || [],
    isLoading,
    isError,
    refetch,
    options: []
  }
}

export default useGlobalSearch
