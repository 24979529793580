/**
 * Application scopes.
 * @enum {string}
 * @readonly
 *
 * Application Scopes are used to restrict access to certain pages or actions.
 */
export enum ApplicationScopes {
  ALL = 'all', // Available in organizations & chains.
  ORGANIZATION = 'organization', // Available only in organizations.
  CHAIN = 'chain' // Available only in chains.
}
