import { useQuery } from '@tanstack/react-query'

import useCurrentUserResource from 'hooks/currentUser/useCurrentUserResource'
import useChainContextStore from 'store/chainContext'

import type { AxiosResponse } from 'axios'
import type { UserStats, UserStatsResponse } from 'hooks/currentUser/useCurrentUserResource'

const useStats = (): { userStats?: UserStats } => {
  const { selectedOrganization } = useChainContextStore((state) => ({
    selectedOrganization: state.selectedOrganization
  }))
  const currentUserResource = useCurrentUserResource()

  const { data: userStats } = useQuery<AxiosResponse<UserStatsResponse>>({
    queryKey: [...currentUserResource.queryKey, 'stats', selectedOrganization?.id],
    queryFn: () =>
      currentUserResource.getStats({ organization_id: Number(selectedOrganization?.id) }),
    enabled: !!selectedOrganization?.id
  })

  return { userStats: userStats?.data?.stats }
}

export default useStats
