import BaseLayout from 'components/layout/BaseLayout'
import MainLayoutContent from 'components/layout/MainLayoutContent'

import MainAppProviders from 'providers/MainAppProviders'

import type { ReactNode } from 'react'

interface MainLayoutProps {
  children: ReactNode
}

const MainLayout = ({ children, ...pageProps }: MainLayoutProps) => (
  <BaseLayout {...pageProps}>
    <MainAppProviders {...pageProps}>
      <MainLayoutContent>{children}</MainLayoutContent>
    </MainAppProviders>
  </BaseLayout>
)

export default MainLayout
