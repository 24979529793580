import { useEffect, useRef } from 'react'

import { usePathname } from 'next/navigation'

const useErrorBoundary = () => {
  const pathName = usePathname()
  const appBoundaryRef = useRef<(() => void) | null>(null)
  const childrenBoundaryRef = useRef<(() => void) | null>(null)

  useEffect(() => {
    if (appBoundaryRef.current) {
      appBoundaryRef.current()
    }

    if (childrenBoundaryRef.current) {
      childrenBoundaryRef.current()
    }
  }, [pathName])

  return { appBoundaryRef, childrenBoundaryRef }
}

export default useErrorBoundary
