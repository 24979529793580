import { Box, Chip, Typography } from '@mui/material'

import type { Theme } from '@mui/material/styles'

const sideNavBadgeStyles = (theme: Theme) => ({
  transform: 'scaleX(3)',
  bgcolor: theme.palette.error.main
})

const sideNavBadgeColorStyles = (theme: Theme) => ({ color: theme.palette.error.light })

type SideNavBadgeProps = {
  variant?: 'default' | 'dot'
  badgeLabel: number | string
}

const defaultVariant = 'default'

const SideNavBadge = ({ variant = defaultVariant, badgeLabel }: SideNavBadgeProps) => {
  if (badgeLabel === 0) return null

  if (variant === 'dot') {
    return (
      <Box
        position="absolute"
        top=".25rem"
        right="5rem"
        height=".625rem"
        width=".625rem"
        borderRadius={8}
        sx={sideNavBadgeStyles}
      />
    )
  }

  const chipLabel = (
    <Typography variant="inherit" sx={sideNavBadgeColorStyles}>
      {badgeLabel}
    </Typography>
  )

  return <Chip label={chipLabel} color="error" size="small" variant="soft" />
}

export default SideNavBadge
