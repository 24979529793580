import { useCallback } from 'react'

import { HTTPMethod } from '@repo/et-types/common/api'

import api from 'utils/api'

import type { Base } from '@repo/et-types'
import type { ApiQueryParams, ApiResponse } from '@repo/et-types/common/api'
import type { RawAxiosRequestHeaders } from 'axios'

export type UseResourceMethodsProps<T extends Base> = {
  defaultParams?: ApiQueryParams
  path: string
  name: string
  transformResponse?: (res: T) => T
  transformRequest?: (data: Partial<T>) => unknown
}

type CustomRequestProps<T> = {
  method: HTTPMethod
  url: string
  data?: T
  headers?: RawAxiosRequestHeaders
  params?: ApiQueryParams
}

export type UseResourceMethods<T extends Base> = {
  getAll: (params?: ApiQueryParams) => Promise<ApiResponse<T[]>>
  post: (data: Partial<T>, params?: ApiQueryParams) => Promise<ApiResponse<T>>
  update: (data: Partial<T>, params?: ApiQueryParams) => Promise<ApiResponse<T>>
  get: (id?: number | string, params?: ApiQueryParams) => Promise<ApiResponse<T>>
  delete: (id: number | string, params?: Record<string, unknown>) => Promise<ApiResponse<T>>
  reorder: (params: ApiQueryParams) => Promise<ApiResponse<T[]>>
  clone: (data: Partial<T>, params?: ApiQueryParams) => Promise<ApiResponse<T>>
  request: <ReqT = T, ReqR = ApiResponse<ReqT>>(props: CustomRequestProps<ReqT>) => Promise<ReqR>
}

const merge = (defaultParams: ApiQueryParams, params?: ApiQueryParams) => {
  if (!params) return defaultParams

  const defaultKeys = Object.keys(defaultParams) as (keyof ApiQueryParams)[]
  const paramsKeys = Object.keys(params) as (keyof ApiQueryParams)[]

  const mergedParams: Record<string, string> = {}

  defaultKeys.forEach((key) => {
    mergedParams[key as string] = defaultParams[key] as string
  })

  paramsKeys.forEach((key) => {
    if (defaultKeys.includes(key)) {
      mergedParams[key as string] = `${defaultParams[key]},${params[key]}`
    } else mergedParams[key as string] = params[key] as string
  })

  return mergedParams
}

const getParams = (defaultParams: ApiQueryParams, params?: ApiQueryParams) =>
  !params?.skip_default_params ? merge(defaultParams, params) : params

const useResourceMethods = <T extends Base>({
  defaultParams = {},
  path,
  name,
  transformResponse = (res: T) => res,
  transformRequest = (data: Partial<T>) => data
}: UseResourceMethodsProps<T>): UseResourceMethods<T> => {
  const getAll = useCallback(
    async (params?: ApiQueryParams): Promise<ApiResponse<T[]>> => {
      const res = await api.request<ApiResponse<T[]>>({
        method: HTTPMethod.GET,
        url: path,
        params: getParams(defaultParams, params)
      })

      res.data.normalizedData = res.data.normalizedData?.map((item) => transformResponse(item))
      return res.data
    },
    [path, transformResponse, defaultParams]
  )

  const post = useCallback(
    async (data: Partial<T>, params?: ApiQueryParams): Promise<ApiResponse<T>> => {
      const res = await api.request<ApiResponse<T>>({
        method: HTTPMethod.POST,
        url: path,
        data: { data: { type: name, attributes: transformRequest(data) } },
        params: getParams(defaultParams, params)
      })

      res.data.normalizedData = transformResponse(res.data.normalizedData)

      return res.data
    },
    [path, transformRequest, transformResponse, name, defaultParams]
  )

  const update = useCallback(
    async (data: Partial<T>, params?: ApiQueryParams): Promise<ApiResponse<T>> => {
      if (!data.id) {
        throw new Error('Trying to edit without providing id')
      }

      const res = await api.request<ApiResponse<T>>({
        method: HTTPMethod.PATCH,
        url: `${path}/${data.id}`,
        data: { data: { type: name, attributes: transformRequest(data) } },
        params: getParams(defaultParams, params)
      })

      res.data.normalizedData = transformResponse(res.data.normalizedData)
      return res.data
    },
    [path, transformRequest, transformResponse, name, defaultParams]
  )

  const get = useCallback(
    async (id?: number | string, params?: ApiQueryParams): Promise<ApiResponse<T>> => {
      const res = await api.request<ApiResponse<T>>({
        method: HTTPMethod.GET,
        url: id ? `${path}/${id}` : path,
        params: getParams(defaultParams, params)
      })

      res.data.normalizedData = transformResponse(res.data.normalizedData)
      return res.data
    },
    [path, transformResponse, defaultParams]
  )

  // `delete` is a reserved keyword
  const doDelete = useCallback(
    async (id: number | string, params?: Record<string, unknown>) => {
      const res = await api.request({
        method: HTTPMethod.DELETE,
        url: `${path}/${id}`,
        data: params
      })

      return res.data
    },
    [path]
  )

  const reorder = useCallback(
    async (params: ApiQueryParams) => {
      const res = await api.request<ApiResponse<T[]>>({
        method: HTTPMethod.POST,
        url: `${path}/reorder`,
        data: { data: { attributes: params, type: name } }
      })

      return res.data
    },
    [path, name]
  )

  const clone = useCallback(
    async (data: Partial<T>, params?: ApiQueryParams): Promise<ApiResponse<T>> => {
      if (!data.id) throw new Error('Trying to edit without providing id')

      const res = await api.request<ApiResponse<T>>({
        method: HTTPMethod.POST,
        url: `${path}/${data.id}/clone_record`,
        data: { data: { type: name, attributes: transformRequest(data) } },
        params: getParams(defaultParams, params)
      })

      return res.data
    },
    [path, name, transformRequest, defaultParams]
  )

  const request = useCallback(
    async <ReqT = T, ReqR = ApiResponse<ReqT>>({
      method,
      url,
      headers,
      data,
      params
    }: CustomRequestProps<ReqT>) => {
      const res = await api.request<ReqR>({
        method: method,
        url: url,
        headers,
        data: data,
        params: params
      })

      return res.data
    },
    []
  )

  return {
    getAll,
    post,
    update,
    get,
    delete: doDelete,
    reorder,
    clone,
    request
  }
}

export default useResourceMethods
