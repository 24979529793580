import { useCallback, useEffect, useMemo, useState } from 'react'

import * as Locales from 'date-fns/locale'

import useChainContextStore from 'store/chainContext'
import useCurrentUserStore from 'store/currentUser'

import type { Locale } from 'date-fns'

const DEFAULT_LOCALE = 'en-US'

const useDateFnsLocale = (): Locale | undefined => {
  const [locale, setLocale] = useState<Locale | undefined>(undefined)

  const orgLocale = useChainContextStore((state) => state.selectedOrganization?.locale)
  const fallbackLocale = useCurrentUserStore((state) => state.organizations?.[0]?.locale) // for chain context
  const localeString = useMemo(
    () => orgLocale ?? fallbackLocale ?? DEFAULT_LOCALE,
    [orgLocale, fallbackLocale]
  )

  const getLocale = useCallback((localeName: string) => {
    if (localeName.includes('-')) {
      const [language] = localeName.split('-')

      getLocale(language)
    } else {
      const locale = Object.values(Locales).find((locale) => locale.code === localeName)

      setLocale(locale)
    }
  }, [])

  useEffect(() => {
    getLocale(localeString)
  }, [getLocale, localeString])

  return locale
}

export default useDateFnsLocale
