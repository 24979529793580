import { useEffect } from 'react'

import { setUser } from '@sentry/browser'

import useCurrentUserStore from 'store/currentUser'

const useInitializeSentryUser = () => {
  const currentUser = useCurrentUserStore()

  useEffect(() => {
    if (!currentUser?.id) return

    /*
      This enriches any events sent to Sentry with the user's email address and unique ID, which
      allows Sentry to group events by user. It also allows us to more easily track down issues
      reported to a specific user that are captured in errors or transactions.

      {{auto}} is a special value that tells Sentry to automatically capture the user's IP address.
    */
    setUser({
      id: currentUser.id,
      username: currentUser.name,
      email: currentUser.email,
      ip_address: '{{auto}}'
    })
  }, [currentUser.id, currentUser.name, currentUser.email])
}

export default useInitializeSentryUser
