import { useMutation } from '@tanstack/react-query'

import useApiSnackBar from 'hooks/useApiSnackBar'
import { ET_CHAIN_CONTEXT_KEY } from 'store/chainContext'
import api from 'utils/api'
import {
  ET_JWT_LOCAL_STORAGE_KEY,
  ET_REVIEW_APP_API_URL_KEY,
  SIGN_IN_URL,
  SIGN_OUT_URL
} from 'utils/constants/global'

import type { AxiosError } from 'axios'

const useLogout = () => {
  const { showError } = useApiSnackBar()

  const logoutMutation = useMutation({
    mutationFn: async () => api.delete(SIGN_OUT_URL),
    onError: (error: AxiosError) => showError({ error }),
    onSuccess: () => {
      localStorage.removeItem(ET_JWT_LOCAL_STORAGE_KEY)
      localStorage.removeItem(ET_REVIEW_APP_API_URL_KEY)
      localStorage.removeItem(ET_CHAIN_CONTEXT_KEY)
      window.location.href = SIGN_IN_URL
    }
  })

  return { logout: logoutMutation.mutate }
}

export default useLogout
