import { type ReactNode, Suspense } from 'react'

import dynamic from 'next/dynamic'

import { ErrorBoundary } from '@sentry/nextjs'

import ErrorFallback from 'components/common/ErrorBoundary/ErrorFallback'
import useErrorBoundary from 'hooks/useErrorBoundary'

import BaseProviders from 'providers/BaseProviders'

interface ErrorFallbackProps {
  resetError: () => void
}

const BaseLayout = ({ children, ...pageProps }: { children: ReactNode }) => {
  const { appBoundaryRef } = useErrorBoundary()

  const renderErrorFallback = ({ resetError }: ErrorFallbackProps) => {
    appBoundaryRef.current = resetError

    return <ErrorFallback resetError={resetError} />
  }

  return (
    <BaseProviders {...pageProps}>
      <Suspense fallback={null}>
        <ErrorBoundary fallback={renderErrorFallback}>{children}</ErrorBoundary>
      </Suspense>
    </BaseProviders>
  )
}

export default dynamic(() => Promise.resolve(BaseLayout), { ssr: false })
