// copied from https://github.com/olosegres/jsona/blob/c7fb018ac08a35babe67fdd34695c57720f4baa1/src/simplePropertyMappers.ts#L94C27-L94C27
// more info on why the custom implementation is needed can be found here:
// https://github.com/olosegres/jsona/issues/65
// https://github.com/TanStack/query/discussions/6025

import { defineRelationGetter } from 'jsona/lib/simplePropertyMappers'

import type {
  IJsonPropertiesMapper,
  TAnyKeyValueObject,
  TJsonApiLinks,
  TJsonaModel,
  TJsonaRelationshipBuild,
  TJsonaRelationships,
  TResourceIdObj
} from 'jsona/lib/JsonaTypes'

export const RELATIONSHIP_NAMES_PROP = 'relationshipNames'

export default class JsonPropertiesMapper implements IJsonPropertiesMapper {
  setRelationshipLinks(): void {
    throw new Error('Method not implemented.')
  }

  setRelationshipMeta(): void {
    throw new Error('Method not implemented.')
  }

  createModel(type: string): TJsonaModel {
    return { type }
  }

  setId(model: TJsonaModel, id: string | number) {
    model.id = id
  }

  setAttributes(model: TJsonaModel, attributes: TAnyKeyValueObject) {
    Object.keys(attributes).forEach((propName) => {
      model[propName] = attributes[propName]
    })
  }

  setMeta(model: TJsonaModel, meta: TAnyKeyValueObject) {
    model.meta = meta
  }

  setLinks(model: TJsonaModel, links: TJsonApiLinks) {
    model.links = links
  }

  setResourceIdObjMeta(model: TJsonaModel, meta: TResourceIdObj) {
    model.resourceIdObjMeta = meta
  }

  setRelationships(model: TJsonaModel, relationships: TJsonaRelationships) {
    Object.keys(relationships).forEach((propName) => {
      if (typeof relationships[propName] === 'function') {
        defineRelationGetter(model, propName, <TJsonaRelationshipBuild>relationships[propName])
      } else {
        model[propName] = structuredClone(relationships[propName])
      }
    })

    const newNames = Object.keys(relationships)
    const currentNames = model[RELATIONSHIP_NAMES_PROP]

    if (currentNames && currentNames.length) {
      model[RELATIONSHIP_NAMES_PROP] = [...currentNames, ...newNames].filter(
        (value, i, self) => self.indexOf(value) === i
      )
    } else {
      model[RELATIONSHIP_NAMES_PROP] = newNames
    }
  }
}
