import { Children, isValidElement } from 'react'

import Slot from 'components/common/Slot'

import type { ReactElement } from 'react'

interface UseSlotsProps {
  children: React.ReactNode
}

const useSlots = ({ children }: UseSlotsProps) => {
  const slots: Record<string, ReactElement> = {}

  Children.forEach(children, (child) => {
    if (isValidElement(child) && child.type === Slot) {
      slots[child.props.name] = child.props.children
    }
  })

  return slots
}

export default useSlots
