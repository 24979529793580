export enum SubscriptionStatus {
  FREE = 'free',
  PARTNER_SANDBOX = 'partner_sandbox',
  CUSTOMER_SANDBOX = 'customer_sandbox',
  TRIALING = 'trialing',
  ACTIVE = 'active',
  PAST_DUE = 'past_due',
  CANCELED = 'canceled',
  UNPAID = 'unpaid',
  PILOT = 'pilot'
}
