import { Menu } from '@mui/icons-material'

import { Box, Chip, IconButton } from '@mui/material'

import { usePathname } from 'next/navigation'

import EtLogo from 'components/common/EtLogo'
import useResponsive from 'hooks/useResponsive'

import useCurrentUserStore from 'store/currentUser'
import { TOP_NAV } from 'utils/constants/layout'

import type { Theme } from '@mui/material'

const headerStyles = (mini: boolean, isETAdmin: boolean) => ({
  top: 0,
  position: 'sticky',
  minHeight: TOP_NAV.H_DESKTOP,
  zIndex: 10,
  display: 'flex',
  justifyContent: mini ? 'center' : 'space-between',
  alignItems: 'center',
  px: 2,
  backgroundColor: (theme: Theme) =>
    isETAdmin ? theme.palette.error.dark : theme.palette.grey[900],
  // Since we are using scaleX to animate the side nav, we need to adjust the
  // position of the icon button a tiny bit to the left. This is because the
  // icon button is not scaled down.
  transform: mini ? 'translateX(-.5rem)' : 'none'
})

type SideNavHeaderProps = {
  toggleSideNavMini: () => void
  onClose: () => void
  mini: boolean
  showSideNavToggle: boolean
}

const SideNavHeader = ({
  toggleSideNavMini,
  onClose,
  mini,
  showSideNavToggle
}: SideNavHeaderProps) => {
  const pathname = usePathname()

  const currentUser = useCurrentUserStore()
  const lgUp = useResponsive('up', 'lg')
  const onButtonClick = lgUp ? toggleSideNavMini : onClose
  const styles = { transform: mini ? 'scaleX(3)' : 'none' }

  const isSettingsPage = pathname.includes('/settings')

  const renderLogoChip = () => {
    if (isSettingsPage) return null

    return <Chip label="Beta" size="small" color="primary" sx={{ mx: 1 }} />
  }

  const renderLogo = () => {
    if (mini) return null

    return (
      <>
        <EtLogo height={48} width={150} />
        {renderLogoChip()}
      </>
    )
  }

  const renderSideNavToggle = () => {
    if (!showSideNavToggle) return null

    return (
      <IconButton onClick={onButtonClick} sx={styles}>
        <Menu />
      </IconButton>
    )
  }

  const finalHeaderStyles = headerStyles(mini, Boolean(currentUser?.isETAdmin))

  return (
    <Box id="side-nav-header" sx={finalHeaderStyles}>
      {renderLogo()}
      {renderSideNavToggle()}
    </Box>
  )
}

export default SideNavHeader
