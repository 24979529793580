import useResourceMethods from 'hooks/useResourceMethods'

import type { UserSetting } from '@repo/et-types'

import type { UseResource } from 'types/resources'

export const USER_SETTING_PATH_NAME = '/v2/user_settings'
export const USER_SETTING_RESOURCE_NAME = 'user_settings'

const useUserSettingResource = (): UseResource<UserSetting> => {
  const methods = useResourceMethods<UserSetting>({
    path: USER_SETTING_PATH_NAME,
    name: USER_SETTING_RESOURCE_NAME
  })

  return {
    ...methods,
    defaultInclude: [],
    pathName: USER_SETTING_PATH_NAME,
    resourceName: USER_SETTING_RESOURCE_NAME,
    toFormData: (data) => data,
    queryKey: ['userSettings']
  }
}

export default useUserSettingResource
