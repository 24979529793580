import useResponsive from 'hooks/useResponsive'

type UseScreenSizesReturnValue = {
  isMdScreen: boolean
  isSmallScreen: boolean
  isXSSmallScreen: boolean
  isLgScreen: boolean
}

const useScreenSizes = (): UseScreenSizesReturnValue => {
  const isMdScreen = useResponsive('down', 'md')
  const isSmallScreen = useResponsive('down', 'sm')
  const isXSSmallScreen = useResponsive('only', 'xs')
  const isLgScreen = useResponsive('up', 'lg')

  return { isMdScreen, isSmallScreen, isXSSmallScreen, isLgScreen }
}

export default useScreenSizes
