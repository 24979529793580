import api from 'utils/api'

import type { AxiosProgressEvent } from 'axios'

export interface UploadFileInterface {
  url: string
  file?: File | 'false'
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void
}

export const multiPartFileUpload = async <T>({
  url,
  file,
  onUploadProgress
}: UploadFileInterface) => {
  const formData = new FormData()

  if (file) {
    formData.append('file', file)
  }

  return await api.post<T>(url, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
    onUploadProgress
  })
}
