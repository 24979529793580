import { createContext, useContext } from 'react'

import Profitwell from './Profitwell'

type ProfitwellContextProps = {
  isLoaded: boolean
  profitwell: Profitwell
}

const ProfitwellContext = createContext<ProfitwellContextProps>({
  isLoaded: false,
  profitwell: new Profitwell(null)
})

const useProfitwellContext = () => useContext(ProfitwellContext)

export { useProfitwellContext, ProfitwellContext }
