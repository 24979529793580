import { type MouseEvent, useMemo } from 'react'

import { Avatar, CardHeader, MenuItem } from '@mui/material'

import { useTranslation } from 'next-i18next'

import MultiOrgIcon from 'components/layout/MultiOrgIcon'

import styles from 'components/pickers/GlobalContextPicker.module.scss'

import useCurrentUserRoles from 'hooks/useCurrentUserRoles'
import useChainContextStore from 'store/chainContext'

import type { Chain, Organization } from '@repo/et-types'

type GlobalContextPickerItemProps = {
  key: string
  item: Chain | Organization
  onClick: (event: MouseEvent<HTMLLIElement>) => void
}

const GlobalContextPickerItem = ({ key, item, onClick }: GlobalContextPickerItemProps) => {
  const { t } = useTranslation()

  const { selectedChain, selectedOrganization } = useChainContextStore((state) => ({
    selectedChain: state.selectedChain,
    selectedOrganization: state.selectedOrganization
  }))
  const { isChainAdmin, getOrganizationUserRole } = useCurrentUserRoles()

  const isOrganization = item.type === 'organization'
  const selected = isOrganization
    ? selectedOrganization?.id === item.id
    : selectedChain?.id === item.id

  const userRoleString = useMemo(() => {
    let result = ''

    if (isOrganization) {
      result = getOrganizationUserRole(item.id).label
    } else if (isChainAdmin) result = t('roles.admin')

    return result
  }, [isChainAdmin, isOrganization, item, getOrganizationUserRole, t])

  const logoSrc = useMemo(() => (item.logo_thumb_url as string) || undefined, [item])

  return (
    <MenuItem
      key={key}
      value={`${item.type}-${item.id}`}
      onClick={onClick}
      selected={selected}
      onKeyDown={(e) => e.stopPropagation()}>
      <CardHeader
        classes={{ root: styles.header, avatar: styles.avatar }}
        title={isOrganization ? item.name : t('chain')}
        subheader={userRoleString}
        subheaderTypographyProps={{ variant: 'body2' }}
        titleTypographyProps={{ variant: 'body1' }}
        avatar={
          isOrganization ? (
            <Avatar sx={{ height: '2rem', width: '2rem' }} src={logoSrc} />
          ) : (
            <MultiOrgIcon className={styles.orgIcon} />
          )
        }
      />
    </MenuItem>
  )
}

export default GlobalContextPickerItem
