import { useCallback, useEffect, useMemo, useState } from 'react'

import { ChevronRight } from '@mui/icons-material'

import {
  Collapse,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack
} from '@mui/material'

import { usePathname } from 'next/navigation'

import { useRouter } from 'next/router'

import SideNavItem from 'components/layout/SideNav/SideNavItem'

import { getBasePath } from 'utils/helpers'

import type { ListItemTextProps, Theme } from '@mui/material'

import type { SideNavItemProps } from 'components/layout/SideNav/SideNavItem'
import type { MouseEvent } from 'react'
import type Routes from 'types/enums/routes'

const dropdownIconStyles = (mini: boolean, collapsed: boolean) => ({
  transform: collapsed || mini ? `rotate(0deg) ${mini && 'scaleX(3)'}` : 'rotate(90deg)',
  position: !mini ? 'relative' : 'absolute',
  right: mini ? '2rem' : 'auto',
  color: (theme: Theme) => (mini ? theme.palette.common.white : theme.palette.text.secondary),
  margin: 0,
  transition: (theme: Theme) =>
    theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut
    })
})

const buttonStyles = (mini: boolean) => ({
  borderRadius: 1,
  justifyContent: mini ? 'center' : 'initial'
})

const iconStyles = (mini: boolean) => ({
  color: (theme: Theme) => theme.palette.common.white,
  marginRight: mini ? 0 : 2,
  // When mini, scale the icon to 3x its size so we counter the scale of the
  // side nav. This prevents the icon from looking stretched.
  transform: mini ? 'scaleX(3)' : 'none'
})

const typographyStyles = (theme: Theme, isActive: boolean, collapseOpen: boolean) => ({
  color: isActive && !collapseOpen ? theme.palette.primary.light : 'inherit',
  fontWeight: isActive && !collapseOpen ? 'semiBold' : 'normal'
})

const SideNavCollapsibleItem = ({
  mini,
  icon,
  label,
  variant,
  subItems,
  onItemHover,
  showArrow,
  defaultExpanded
}: SideNavItemProps) => {
  const pathname = usePathname()
  const router = useRouter()

  const currentPath = router.asPath
  const isDropdown = variant === 'dropdown'
  const isHeader = variant === 'header'

  const initialCollapsed = useMemo(
    () =>
      isDropdown ||
      (isHeader && !defaultExpanded && subItems
        ? !subItems.some(
            (item) =>
              item.href === currentPath ||
              item.subItems?.some((subItem) => subItem.href === currentPath)
          )
        : false),
    [isDropdown, isHeader, defaultExpanded, subItems, currentPath]
  )

  const [collapsed, setCollapsed] = useState(initialCollapsed)
  const collapseOpen = mini ? !icon : !collapsed

  const isActive = useMemo(() => {
    if (subItems) {
      const basePathname = getBasePath(pathname as Routes)

      return subItems.some((item) => item.href && basePathname?.startsWith(item.href))
    }

    return false
  }, [pathname, subItems])

  // Since we are using scaleX to animate the side nav, we need to adjust the
  // position of the icon's a tiny bit to the left. This is because the icon's
  // are not scaled down.
  const collapseStyle = useMemo(
    () => ({
      transform: mini ? 'translateX(-.5rem)' : 'none',
      pl: icon ? 3.5 : 0,
      transition: mini ? 'none' : undefined
    }),
    [mini, icon]
  )

  useEffect(() => {
    if (mini && collapsed && variant === 'header') setCollapsed(false)

    if (mini && !collapsed && variant === 'dropdown') setCollapsed(true)
  }, [collapsed, mini, variant])

  const typographyProps: ListItemTextProps['secondaryTypographyProps'] = {
    variant: variant === 'header' ? 'overline' : 'body2',
    sx: (theme) => typographyStyles(theme, isActive, collapseOpen)
  }

  const showButton = (mini && icon) || !mini

  const handleOnMouseEnter = (e: MouseEvent<HTMLDivElement>) => onItemHover?.(e, subItems || [])

  const handleOnMouseLeave = () => onItemHover?.(null, [])

  const listItemButtonClick = useCallback(() => {
    if (mini) return

    setCollapsed(!collapsed)
  }, [mini, collapsed])

  const onMouseEnter = (e: MouseEvent<HTMLDivElement>) => {
    if (isDropdown && mini) return handleOnMouseEnter(e)

    return undefined
  }

  const onMouseLeave = () => {
    if (isDropdown && mini) return handleOnMouseLeave()

    return undefined
  }

  const isSelected = isActive && !collapseOpen
  const listButtonStyles = buttonStyles(Boolean(mini))
  const listIconStyles = iconStyles(Boolean(mini))
  const finalDropdownIconStyles = dropdownIconStyles(Boolean(mini), collapsed)

  const renderItemText = () => {
    if (mini) return null

    return <ListItemText secondary={label} secondaryTypographyProps={typographyProps} />
  }

  const renderDropdownArrow = () => {
    if (!isDropdown && !showArrow) return null

    return (
      <ListItemIcon sx={finalDropdownIconStyles}>
        <ChevronRight />
      </ListItemIcon>
    )
  }

  const renderButton = () => {
    if (!showButton) return null

    return (
      <ListItem disableGutters disablePadding>
        <ListItemButton
          selected={isSelected}
          sx={listButtonStyles}
          disableGutters={!icon}
          onClick={listItemButtonClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}>
          <ListItemIcon sx={listIconStyles}>{icon}</ListItemIcon>
          {renderItemText()}
          {renderDropdownArrow()}
        </ListItemButton>
      </ListItem>
    )
  }

  const renderSubMenuItems = () =>
    subItems?.map((subItem, index) => (
      <SideNavItem
        key={index}
        mini={mini}
        inCollapse={isDropdown}
        onItemHover={onItemHover}
        {...subItem}
      />
    ))

  return (
    <>
      {renderButton()}
      <Collapse in={collapseOpen} sx={collapseStyle}>
        <Stack>{renderSubMenuItems()}</Stack>
      </Collapse>
    </>
  )
}

export default SideNavCollapsibleItem
