/**
 * All the organization features within the platform. These are pulled from the features table in the database.
 */
export enum OrganizationFeatures {
  AUTOMATIONS = 'automations',
  BOOKING_SCHEDULED_PAYMENTS = 'booking_scheduled_payments',
  CALENDAR_SYNCS = 'calendar_syncs',
  CONNECTED_INVOICES = 'connected_invoices',
  CHOICE_GROUP_OPTIONS = 'choice_group_options',
  GRC = 'grc', // Guest Room Control
  GUEST_PORTAL = 'guest_portal',
  LNR = 'lnr', // Local Negotiated Rate / Volume Rates / Volume Contracts
  MARKETPLACE = 'marketplace',
  MENU_ITEM_BUNDLES = 'menu_item_bundles',
  MEWS_ACCOUNTS = 'mews_accounts',
  MEWS_CONTACTS_BETA = 'mews_contacts_beta',
  MEWS_PREPAYMENT_INVOICE_SYNC = 'mews_prepayment_invoice_sync',
  MULTILINGUAL_DOCS = 'multilingual_docs',
  NYLAS_EMAIL_INTEGRATION = 'nylas_email_integration',
  PACKAGES = 'packages',
  PACKAGES_DOCUMENT_ELEMENT = 'packages_document_element',
  PMS = 'pms',
  PROPOSALS = 'proposals',
  RFPS = 'rfps',
  ROLES_UI = 'roles_ui',
  STORE_FRONTS = 'store_fronts',
  SYNC_ALL_EMAIL = 'sync_all_email',
  TEXTING = 'texting',
  WORKFLOWS = 'workflows',
  SAVABLE_EXPORTS = 'savable_exports'
}

/**
 * All the chain features within the platform. These are pulled from the features table in the database.
 */
export enum ChainFeatures {
  GLOBAL_RFPS = 'global_rfps',
  NEW_FRONTEND_MAIN_APP = 'new_frontend_main_app'
}
