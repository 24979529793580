/* eslint-disable @typescript-eslint/no-explicit-any */

/*
  link to original resource: https://github.com/olosegres/jsona/blob/master/src/builders/JsonDeserializer.ts
  relationships are mapped by buildDataFromIncludedOrData method which in turn calls buildIncludedInObject method
  buildIncludedInObject method creates an object with key as type + id and value as included object.
  For pms_records, included object is created with key as type + attributes.pms_record_id and value as included object.
*/
import type {
  IDeserializeCache,
  IJsonPropertiesMapper,
  IJsonaDeserializer,
  TJsonApiBody,
  TJsonApiData,
  TJsonaModel,
  TJsonaRelationships,
  TResourceIdObj
} from 'jsona/lib/JsonaTypes'

class JsonDeserializer implements IJsonaDeserializer {
  protected pm!: IJsonPropertiesMapper
  protected dc!: IDeserializeCache
  protected body: any
  protected dataInObject: any
  protected preferNestedDataFromData = false
  protected includedInObject: any

  constructor(
    propertiesMapper: IJsonPropertiesMapper,
    deserializeCache: IDeserializeCache,
    options: any
  ) {
    this.setPropertiesMapper(propertiesMapper)
    this.setDeserializeCache(deserializeCache)

    if (!options) {
      return
    }

    if (options.preferNestedDataFromData) {
      this.preferNestedDataFromData = true
    }
  }

  setDeserializeCache(dc: any): void {
    this.dc = dc
  }

  setPropertiesMapper(pm: any): void {
    this.pm = pm
  }

  setJsonParsedObject(body: TJsonApiBody): void {
    this.body = body
  }

  build(): TJsonaModel | Array<TJsonaModel> {
    const { data } = this.body
    let stuff

    if (Array.isArray(data)) {
      stuff = []
      const collectionLength = data.length

      for (let i = 0; i < collectionLength; i++) {
        if (data[i]) {
          const model = this.buildModelByData(data[i])

          if (model) {
            stuff.push(model)
          }
        }
      }
    } else if (data) {
      stuff = this.buildModelByData(data)
    }

    return stuff ?? []
  }

  buildModelByData(data: TJsonApiData, resourceIdObj?: TResourceIdObj): TJsonaModel {
    const cachedModel = this.dc?.getCachedModel(data, resourceIdObj as TResourceIdObj)

    if (cachedModel) {
      return cachedModel
    }

    const model = this.pm?.createModel(data.type)

    this.dc?.handleModel(model as TJsonaModel, data, resourceIdObj as TResourceIdObj) // should be called before this.pm?.setRelationships(model, relationships);

    if (model) {
      this.pm?.setId(model, data.id as string | number)

      if (data.attributes) {
        this.pm?.setAttributes(model, data.attributes)
      }

      if (data.meta && this.pm?.setMeta) {
        this.pm?.setMeta(model, data.meta)
      }

      if (data.links && this.pm?.setLinks) {
        this.pm?.setLinks(model, data.links)
      }

      if (resourceIdObj?.meta) {
        this.pm?.setResourceIdObjMeta(model, resourceIdObj.meta)
      }

      const relationships: null | TJsonaRelationships = this.buildRelationsByData(data, model)

      if (relationships) {
        this.pm?.setRelationships(model, relationships)
      }
    }

    return model as TJsonaModel
  }

  buildRelationsByData(data: TJsonApiData, model: TJsonaModel): TJsonaRelationships | null {
    const readyRelations: TJsonaRelationships = {}

    if (data.relationships) {
      for (const k in data.relationships) {
        const relation = data.relationships[k]

        if (Array.isArray(relation.data)) {
          readyRelations[k] = []

          const relationDataLength = relation.data.length
          let resourceIdObj

          for (let i = 0; i < relationDataLength; i++) {
            resourceIdObj = relation.data[i]

            if (!resourceIdObj) {
              continue
            }

            const dataItem = this.buildDataFromIncludedOrData(resourceIdObj.id, resourceIdObj.type)

            if (Array.isArray(readyRelations[k])) {
              ;(readyRelations[k] as TJsonaModel[]).push(
                this.buildModelByData(dataItem, resourceIdObj)
              )
            }
          }
        } else if (relation.data) {
          const dataItem = this.buildDataFromIncludedOrData(relation.data.id, relation.data.type)

          readyRelations[k] = this.buildModelByData(dataItem, relation.data)
        } else if (relation.data === null) {
          readyRelations[k] = null as any
        }

        if (relation.links) {
          const { setRelationshipLinks } = this.pm

          if (setRelationshipLinks) {
            setRelationshipLinks(model, k, relation.links)
          }
        }

        if (relation.meta) {
          const { setRelationshipMeta } = this.pm

          if (setRelationshipMeta) {
            setRelationshipMeta(model, k, relation.meta)
          }
        }
      }
    }

    if (Object.keys(readyRelations).length) {
      return <TJsonaRelationships>readyRelations
    }

    return null
  }

  buildDataFromIncludedOrData(id: string | number, type: string): TJsonApiData {
    if (this.preferNestedDataFromData) {
      const dataObject = this.buildDataInObject()
      const dataItemFromData = dataObject[type + id]

      if (dataItemFromData) {
        return dataItemFromData
      }
    }

    const includedObject = this.buildIncludedInObject()
    const dataItemFromIncluded = includedObject[type + id]

    if (dataItemFromIncluded) {
      return dataItemFromIncluded
    }

    if (!this.preferNestedDataFromData) {
      const dataObject = this.buildDataInObject()
      const dataItemFromData = dataObject[type + id]

      if (dataItemFromData) {
        return dataItemFromData
      }
    }

    return { id: id, type: type }
  }

  buildDataInObject(): { [key: string]: TJsonApiData } {
    if (!this.dataInObject) {
      this.dataInObject = {}

      const { data } = this.body
      const dataLength = data.length

      if (data && dataLength) {
        for (let i = 0; i < dataLength; i++) {
          const item = data[i]

          this.dataInObject[item.type + item.id] = item
        }
      } else if (data) {
        this.dataInObject[data.type + data.id] = data
      }
    }

    return this.dataInObject
  }

  buildIncludedInObject(): { [key: string]: TJsonApiData } {
    if (!this.includedInObject) {
      this.includedInObject = {}

      if (this.body.included) {
        const includedLength = this.body.included.length

        for (let i = 0; i < includedLength; i++) {
          const item = this.body.included[i]

          this.includedInObject[item.type + item.id] = item
        }
      }
    }

    return this.includedInObject
  }
}

export default JsonDeserializer
